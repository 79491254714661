<template>
  <div>
    <el-button type="primary" style="margin-bottom: 10px" @click="printSomething">打印</el-button>
    <div id="content1" ref="content">
      <div class="boxBackground">
        <div class="box">
          <!--          <div style="height: 3px"></div>-->
          <div class="buttonStyle">
            电梯安全知识
          </div>
          <div :style="{width:width*0.25 - widthRadio*20+'px',margin:'0px auto',paddingLeft:20+'px'}">
            <el-row style="text-align: center">
              <el-col :span="8"><div class="pictureBox">1</div></el-col>
              <el-col :span="8"><div class="pictureBox">2</div></el-col>
              <el-col :span="8"><div class="pictureBox">3</div></el-col>
            </el-row>
          </div>
          <div :style="{width:width*0.25 - widthRadio*20+'px',margin:'0px auto',paddingLeft:20+'px'}">
            <el-row>
              <el-col :span="8"><div class="pictureBox">1</div></el-col>
              <el-col :span="8"><div class="pictureBox">2</div></el-col>
              <el-col :span="8"><div class="pictureBox">3</div></el-col>
            </el-row>
          </div>
          <div :style="{width:width*0.25 - widthRadio*20+'px',margin:'0px auto',paddingLeft:20+'px'}">
            <el-row>
              <el-col :span="8"><div class="pictureBox">1</div></el-col>
              <el-col :span="8"><div class="pictureBox">2</div></el-col>
              <el-col :span="8"><div class="pictureBox">3</div></el-col>
            </el-row>
          </div>

          <div class="textBox">
            文字站位
          </div>
        </div>
      </div>

      <div :style="{width:width*0.25+'px'}">2</div>
      <div :style="{width:width*0.25+'px'}">3</div>
      <div :style="{width:width*0.25+'px'}">4</div>
    </div>
    <span slot="footer">
      <el-button @click="dialogVisible=false">{{$l("common.cancel", "取消")}}</el-button>
    </span>
  </div>
  <!--    <el-date-picker-->
  <!--      v-model="value1"-->
  <!--      v-loading="loading"-->
  <!--      type="date"-->
  <!--      :picker-options="pickerOptions"-->
  <!--      style="margin-bottom: 20px"-->
  <!--      placeholder="选择日期"-->
  <!--      @change="onChangeDate">-->
  <!--    </el-date-picker>-->
</template>

<script>



  import moment from "moment";
  import print from "print-js";

  export default {
    data() {
      return {
        width:1920,
        height:720,
        dialogVisible: true,
        contentLoading: false,
        submitLoading: false,
        tabActive: "first",
        floorStart: 0,
        floorEnd: 0,
        loading:false,
        id:0,
        model: [],
        filter:{
          orderColumnName:"timestamp",
        },
        radio:234/this.height,
        widthRadio:1920/750,
        data:{},
        value1:"",
        pickerOptions: {
          disabledDate:(time)=> {
            return time.getTime() > Date.now()|| !this.model.arr.some(item=>item.time===time.getTime());
          },
        // shortcuts: [{
        //   text: "最新一次",
        //   onClick(picker) {
        //     picker.$emit("pick", new Date());
        //   },
        // }, {
        //   text: "最近一次",
        //   onClick(picker) {
        //     const date = new Date();
        //     date.setTime(date.getTime() - 3600 * 1000 * 24 * 14);
        //     picker.$emit("pick", date);
        //   },
        // }],
        },
      };
    },

    methods: {
      addWatermark(content) {
        this.$watermark.set(content,this.$refs.content);
      },
      printSomething() {
        console.log(138,this.$refs.content);

        // const table = document.querySelector(".tb");
        // table.style.margin="20px auto";
        // table.style.margin = "0 0";
        print({
          printable:"content1",
          type:"html",
          targetStyles:["*"],
          onPrintDialogClose:()=>{

          }});


      },
      onChangeDate(event) {
        console.log(event.getTime(),299);
        const num = this.model.arr.findIndex(item=>item.time===event.getTime());
        this.getDetail(this.model.arr[num].id);
      },
      open(id) {
        this.model.id = id;
        // this.model.elevatorId = id;
        this.id =id;
        this.dialogVisible = true;
        // this.getData();
      },
      openDeatail(id) {
        this.dialogVisible = true;
        this.getDetail(id);
      },
      getData() {
        if (this.id) {
          const parmars  = {...this.filter, elevatorId:this.id};
          this.filter = {...this.filter, elevatorId:this.id};
          // this.contentLoading = true;
          this.loading=true;
          this.$http.get("basic/elevator-half-month-operation-report/list",parmars).then(data => {
            this.contentLoading = false;
            const arr = data.map(item=>{return {time:moment(item.createTime).format("YYYY-MM-DD"),id:item.id};});
            const list = arr.map(item=>{
              return {time:moment(item.time).toDate().getTime(),id:item.id};});
            this.model.arr = list;
            this.loading=false;
            if (list instanceof Array&&list.length>0) {
              this.value1=moment(list[list.length-1].time);

              this.getDetail(list[list.length-1].id);
            }
          }).catch(()=>{
            this.loading=false;
          });
        } else {
        // this.initMap();
        }
      },
      getDetail(id) {

        this.$http.get(`basic/elevator-half-month-operation-report/${id}`).then(data => {
          let statusDesc="";
          if (data.elevatorStatus===0) {
            statusDesc="停用";
          }else if (data.elevatorStatus===1) {
            statusDesc="在用";
          }else {
            statusDesc="注销";
          }
          this.addWatermark(data.realEstateName+"-"+data.regCode);
          this.data = {...data,statusDesc};
          console.log(data,333);
        });
      },

    },
  };
</script>

<style lang="scss" scoped>
$heightRadio : 720/234;
$widthRadio : 1920/750;
#content1{
  display: flex;
  flex-direction: row;
}
.box{
  padding-top: $heightRadio*0.5px;
  width: $widthRadio*167px;
  height: $heightRadio *227.5px;
  background: #c7deff;
  border-radius: $widthRadio*6px;
  margin: 10px auto;
}
.boxBackground{
  width: $widthRadio*174px;
  height: $heightRadio *234.5px;
  background: #3268b2;
}
.rpTitle{
  width:200px;
  text-align: center;
  border: 1px solid black;
}
.rpContent{
  width: 200px;
  text-align: center;
  border: 1px solid black;
}
.rpBoxTitle{
  background: #92d050;
  width: 100%;
  font-size: 20px;
  text-align: center;
  border: 1px solid #92d050;
}

.buttonStyle{
  margin: $heightRadio*3px auto;
  width: $widthRadio*167px;
  height:$heightRadio*19px;
  background: #00357f;
  border-radius: $heightRadio*9.5px;
  text-align: center;
  font-size: 18px;
  font-family: zihunxingmouhei, zihunxingmouhei-Regular;
  font-weight: 900;
  color: #ffffff;
  letter-spacing: 0.27px;
  line-height: $heightRadio*19px;
}
.pictureBox{
  margin-top: $heightRadio*5.5px;
  width: $widthRadio*42px;
  height: $heightRadio*42px;
  background: #ffffff;
  border: 0.5px solid #00357f;
  border-radius: $heightRadio*4.5px;
}
.textBox{
  width: $widthRadio*147.5px;
  height: $heightRadio*49.5px;
  background: linear-gradient(90deg,#ebf3ff 0%, #fff7eb 99%);
  border: 0.5px solid #00357f;
  border-radius:$widthRadio* 4px;
  margin: $heightRadio*6px auto;
}
</style>
